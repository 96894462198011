import { render, staticRenderFns } from "./mobileSSO.vue?vue&type=template&id=1db3d89d&"
import script from "./mobileSSO.vue?vue&type=script&lang=ts&"
export * from "./mobileSSO.vue?vue&type=script&lang=ts&"
import style0 from "./mobileSSO.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeaderSSO: require('/app/packages/auth/components/layout/HeaderSSO.vue').default,CaSnackbar: require('/app/node_modules/@petlove/caramelo-vue-components/src/Snackbar/index.vue').default})
