
// @ts-ignore
import colorTheme from '@petlove/webstore-mixins/nuxt/color-theme.mjs';
import { defineComponent } from '@vue/composition-api';
import { useNamespacedState } from 'vuex-composition-helpers';

import { SSOState } from '@/store/sso/state';
import { UiState } from '@/store/ui/state';
import { WhitelabelState } from '@/store/whitelabel/state';

export default defineComponent({
  mixins: [colorTheme],
  setup() {
    const { snackbar } = useNamespacedState<SSOState>('sso', ['snackbar']);
    const { webView } = useNamespacedState<UiState>('ui', ['webView']);
    const { store: infoWL } = useNamespacedState<WhitelabelState>(
      'whitelabel',
      ['store']
    );

    return { infoWL, snackbar, webView };
  }
});
